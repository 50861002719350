import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import "./index.scss";
import { datetimeParser } from "others/services";
import { Dialog } from '@material-ui/core';
import Prontuario from "components/prontuario";
import { useStateValue } from 'contexts';

function Atendimentos(){

    document.title = 'BSI - Lista de Atendimentos';

    const [atendimentos, setAtendimentos] = useState([]);
    const [prontuario, setProntuario] = useState(null);
    const [{socket}, dispatch] = useStateValue();

    useEffect(() => {
        if(socket) loadClientes();
    }, [socket]);

    async function loadClientes(){
        
        socket.emit("atendimentos_list");
        socket.on("atendimentos_list", ({payload}) => payload && setAtendimentos(payload) );

    }

    return ( 
        <>
            <Grid className="atendimentos listar" container spacing={2}>
                <Dialog maxWidth="md" fullWidth open={prontuario ? true : false} onClose={() => setProntuario(null)} aria-labelledby="form-dialog-title">
                    <Prontuario id={prontuario} />
                </Dialog>
                <div className="page-box">
                    <div className="title">
                        Lista de Atendimentos
                    </div>
                    <Paper elevation={3} className="content">
                        <table>
                            <thead>
                                <tr>
                                    <th>Protocolo</th>
                                    <th>Cliente</th> 
                                    <th>Vendedor</th> 
                                    <th>Inicio</th> 
                                    <th>Fim</th> 
                                    <th>Ações</th> 
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    atendimentos.map(value => (
                                        <tr key={value.id}>
                                            <td>#{value.id}</td>
                                            <td>{value.cliente.nome}</td>
                                            <td>{value.vendedor.nome}</td>
                                            <td>{ datetimeParser(value.created_at)}</td>
                                            <td>{ datetimeParser(value.end_at)}</td>
                                            <td>
                                                <a className="btn blue-bg small" onClick={() => setProntuario(value.beneficiario.id)}>Abrir Atendimento</a>
                                                {/* <a className="btn gray-bg small" onClick={() => remove(value.id)}>Remover</a> */}
                                            </td>
                                        </tr>
                                    ))
                                }
                            
                            </tbody>
                        </table>
                        <br/>
                        <br/>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Atendimentos;