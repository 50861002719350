import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import "./index.scss";
import axios from 'axios';
import { datetimeParser } from "others/services";
import { LinearProgress } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import Notificacao from "components/notificacao";

function Exames(){

    document.title = 'BSI - Lista de Notificações';

    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacao, setNotificacao] = useState(null);

    useEffect(() => {
        loadNotificacoes();
    }, []);

    async function loadNotificacoes(){
        const request = await axios.get(`notificacoes/global`);
        const response = request.data.payload;
        setNotificacoes(response);
    }

    return ( 
        <>
            <Dialog maxWidth="md" fullWidth open={notificacao ? true : false} onClose={() => setNotificacao(null)} aria-labelledby="form-dialog-title">
                <Notificacao id={notificacao} />
            </Dialog>
            <Grid className="notificacoes listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Notificações
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Protocolo</th>
                                <th>Titulo</th>
                                <th>Status</th> 
                                <th>Data</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notificacoes.map(value => (
                                    <tr key={value.id}>
                                        <td>#{value.id}</td>
                                        <td>{value.titulo}</td>
                                        <td>
                                        <Grid container className="statistics" direction="column">
                                            <Grid container justify="space-between">
                                                <h5></h5>
                                                <h5><b>{value.total_abertos} / {value.total_notificados} (Total)</b></h5>
                                            </Grid>
                                            <LinearProgress className="progress-blue" variant="determinate" value={value.total_abertos / value.total_notificados * 100} />
                                        </Grid>
                                        </td>
                                        <td>{ datetimeParser(value.created_at)}</td>
                                        <td>
                                            <button className="btn blue-bg small" onClick={() => setNotificacao(value.id)}>Visualizar</button>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Button href='notificacoes/novo' variant="contained" color="primary">Adicionar</Button>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Exames;