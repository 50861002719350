import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useParams } from "react-router-dom";
import { datetimeParser } from "others/services";

import axios from "axios";
import "./index.scss";

function NotificacoesSingle(){

    const [notificacao, setNotificacao] = useState({});
    const params = useParams();

    useEffect(() => {
        document.title = 'BSI - Notificacão';
        const { id } = params;
        const parserID = Number(id);
        if(parserID) getNotifications(parserID);
    }, []);

    async function getNotifications(id){
        const request = await axios.get(`notificacoes/${id}`);
        const response = request.data.payload;
        setNotificacao(response);
        if(!response.aberto) setReaded(id);
    }
    
    async function setReaded(id){
        await axios.put(`notificacoes/${id}`);
    }

    return ( 
        <>
            <Grid className="notificacoes single" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Notificação Recebida
                        </div>
                        <Paper elevation={3} className="content">
                            <Grid container justify="space-between">
                                <h3>{notificacao.titulo}</h3>
                                <p className="date">{datetimeParser(notificacao.created_at)}</p>
                            </Grid>
                            <p>{notificacao.descricao}</p>
                        </Paper>
                    </div>
                    
                </Grid>

            </Grid>
            
        
        </>
    )
    
}

export default NotificacoesSingle;