import React, { useState, useEffect, useRef } from 'react';
import { Grid, Paper, Button, CircularProgress, Switch } from '@material-ui/core';
import "./index.scss";
import { useStateValue } from 'contexts';
import firebase from "firebase/app";
import "firebase/storage";

function Atendimentos(){

    document.title = 'BSI - Customizações de Atendimento';

    const logoFile = useRef();
    const BannerFile = useRef();

    const [texts, setTexts] = useState({});
    const [colors, setColors] = useState({});
    const [banner, setBanner] = useState({img: "//via.placeholder.com/350x200"});
    const [logo, setLogo] = useState({img: "//via.placeholder.com/350x200"});
    const [isLoading, setLoading] = useState({});
    const [{socket}, dispatch] = useStateValue();

    useEffect(() => {
        if(socket) mounted();
    }, [socket]);

    async function mounted(){
        socket.emit("configuracoes_find");
        
        socket.on("configuracoes_find", ({payload}) => {
            
            if(payload) {

                const { cores, banner, logo, textos } = payload;

                console.log(logo);

                if(cores) setColors(cores);
                if(banner) setBanner(banner);
                if(logo) setLogo(logo);
                if(textos) setTexts(textos);

            }
        });

    }

    async function doUpload(file, type){
        
        setLoading({ [type]: true });

        if (file) {

            const storageRef = firebase.storage().ref();
            var uploadTask = storageRef.child(`customizacao/${type}/${file.name}`).put(file);

            uploadTask.then(async () => {

                const url = await uploadTask.snapshot.ref.getDownloadURL();
                if(type === "logo") setLogo({...logo, img: url});
                if(type === "banner") setBanner({...banner, img: url});
                setLoading({});
               
            });

        }
    }

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    function save(){

        const obj = { textos: texts, banner, logo, cores: colors };
        socket.emit("configuracoes_update", obj);
           
        socket.on('configuracoes_update', (response) => {
            console.log(response);
            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
        });
        
    }

    return ( 
        <>
            <Grid className="customizacao" container spacing={2}>
                <Grid item md>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <div className="page-box">
                                <div className="title">
                                    Logotipo
                                </div>
                                <Paper elevation={3} className="content">
                                    <Grid container spacing={2}>
                                        <Grid item md>
                                            <div className="input-wrapper">
                                                <label>Titulo (Opcional)</label>
                                                <input defaultValue={logo.titulo} onChange={(evt) => setLogo({...logo, titulo: evt.target.value})} />
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="input-wrapper">
                                                <label>Tamanho da logo (%)</label>
                                                <input defaultValue={logo.tamanho} type="number" onChange={(evt) => setLogo({...logo, tamanho: evt.target.value})} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            { isLoading.logo && <CircularProgress size={32} style={{marginTop: 25}} /> }
                                            { !isLoading.logo && logo.img && <img src={logo.img} width="100%" height="auto"/> }
                                        </Grid>
                                        <Grid item xs >
                                            Imagem:
                                            <br />
                                            <br />
                                            <Button onClick={() => logoFile.current.click() } className="primary" variant="outlined">Selecionar Imagem</Button>
                                            <input
                                                ref={logoFile}
                                                type="file"
                                                style={{display: "none"}}
                                                onChange={evt => doUpload(logoFile.current.files[0], "logo")}
                                                accept="image/*"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <div className="page-box">
                                <div className="title">
                                    Banner principal
                                </div>
                                <Paper elevation={3} className="content">
                                    <Grid container spacing={2}>
                                        <Grid item md>
                                            <div className="input-wrapper">
                                                <label>Titulo</label>
                                                <input defaultValue={banner.titulo} onChange={(evt) => setBanner({...banner, titulo: evt.target.value})} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md>
                                            <div className="input-wrapper">
                                                <label>Subtitulo</label>
                                                <textarea defaultValue={banner.subtitulo} onChange={(evt) => setBanner({...banner, subtitulo: evt.target.value})}></textarea>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={5}>
                                                    { isLoading.banner && <CircularProgress size={32} style={{marginTop: 25}} /> }
                                                    { !isLoading.banner && banner.img && <img src={banner.img} width="100%" height="auto"/> }
                                                </Grid>
                                                <Grid item xs>
                                                    Imagem:
                                                    <br />
                                                    <br />
                                                    <Button onClick={() => BannerFile.current.click() } className="primary" variant="outlined">Selecionar Imagem</Button>
                                                    <input
                                                        ref={BannerFile}
                                                        type="file"
                                                        style={{display: "none"}}
                                                        onChange={evt => doUpload(BannerFile.current.files[0], "banner")}
                                                        accept="image/*"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <h4>Botão central</h4>
                                            <Grid container spacing={2}>
                                                <Grid item md>
                                                    <div className="input-wrapper">
                                                        <label>Label</label>
                                                        <input defaultValue={banner.label} onChange={(evt) => setBanner({...banner, label: evt.target.value})} />
                                                    </div>
                                                </Grid>
                                                <Grid item md>
                                                    <div className="input-wrapper">
                                                        <label>URL</label>
                                                        <input defaultValue={banner.url} onChange={(evt) => setBanner({...banner, url: evt.target.value})} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item md>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <div className="page-box">
                                <div className="title">
                                    Mensagens
                                </div>
                                <Paper elevation={3} className="content">
                                    <div className="input-wrapper">
                                        <label>Online</label>
                                        <textarea defaultValue={texts.online} onChange={(evt) => setTexts({...texts, online: evt.target.value})}></textarea>
                                    </div>
                                    <div className="input-wrapper">
                                        <label htmlFor="input-11">Offline</label>
                                        <textarea defaultValue={texts.offline} onChange={(evt) => setTexts({...texts, offline: evt.target.value})}></textarea>
                                    </div>
                                </Paper>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <div className="page-box">
                                <div className="title">
                                    Cores
                                </div>
                                <Paper elevation={3} className="content">
                                    <Grid container spacing={2}>
                                        <Grid item md>
                                            <div className="input-wrapper">
                                                <label>Primária</label>
                                                <input defaultValue={colors.primaria} onChange={(evt) => setColors({...colors, primaria: evt.target.value})} />
                                            </div>
                                        </Grid>
                                        <Grid item md>
                                            <div className="input-wrapper">
                                                <label>Textos</label>
                                                <input defaultValue={colors.textos} onChange={(evt) => setColors({...colors, textos: evt.target.value})} />
                                            </div>
                                        </Grid>
                                        <Grid item md>
                                            <div className="input-wrapper">
                                                <label>Background</label>
                                                <input defaultValue={colors.background} onChange={(evt) => setColors({...colors, background: evt.target.value})} />
                                            </div>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item md={4}>
                                                    <div className="input-wrapper">
                                                        <label htmlFor="input-4">Tema Escuro</label>
                                                        <Switch
                                                            checked={colors.dark || false}
                                                            color="primary"
                                                            onClick={() => setColors({...colors, dark: !colors.dark })}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item md>
                                                    <div className="input-wrapper">
                                                        <label>Cor do textos nos botões</label>
                                                        <input defaultValue={colors.botoes} onChange={(evt) => setColors({...colors, botoes: evt.target.value})} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../beneficiarios" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" onClick={save} variant="contained">Salvar</Button>
                </Grid>
            </Grid>
        </>
    )
    
}

export default Atendimentos;