import React, { useState, useEffect } from 'react';
import { Grid, Chip, Button, Paper } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { cnpjMask, dateParser } from "others/services";

function Entidades(){

    document.title = 'BSI - Lista de Entidades';

    const [entidades, setEntidades] = useState([]);

    useEffect(() => {
        loadEntidades();
    }, []);

    async function loadEntidades(){
        const request = await axios.get("/entidades/");
        const response = request.data.payload;
        setEntidades(response);
    }

    async function removeExame(id){
        alert("Ainda não foi implementado a regra pra exclusão de entidade!");
    }

    return ( 
        <>
            <Grid className="entidades listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Entidades Cadastradas
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Razão Social</th>
                                <th>Email</th> 
                                <th>CNPJ</th> 
                                <th>Data</th> 
                                <th>Situação</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                entidades.map(value => (
                                    <tr key={value.id}>
                                        <td>{value.id}</td>
                                        <td>{value.razao_social}</td>
                                        <td>{value.usuario.email}</td>
                                        <td>{ cnpjMask(value.cnpj) }</td>
                                        <td>{ dateParser(value.usuario.created_at)}</td>
                                        <td> <Chip size="small" color={value.usuario.ativo ? "primary" : "danger"} label={value.usuario.ativo ? "Habilitado" : "Desabilitado"} /></td>
                                        <td>
                                            <a className="btn blue-bg small" href={`entidades/${value.id}`}>Editar</a>
                                            <a className="btn gray-bg small" onClick={() => removeExame(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Button href='entidades/novo' variant="contained" color="primary">Adicionar</Button>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Entidades;