import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Grid, Badge } from '@material-ui/core';
import { dateParser } from "others/services";
import { useStateValue } from 'contexts';
import DropDownMenu from "./dropdown";
import { FiBell } from "react-icons/fi";
import { useHistory } from "react-router-dom";

export default function NotificationsHeaderComponent() {

  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(0);
  const [{ user: { role } }, dispatch] = useStateValue();
  const history = useHistory();

  useEffect(() => {
    getNotifications();
  }, []);

  async function getNotifications(){
    const request = await axios.get(`notificacoes`);
    const response = request.data.payload;
    const unread = response.filter(value => value.aberto === false);
    const limited = response.filter((value, i) => i < 4);
    setUnread(unread.length);
    setNotifications(limited);
  }

  function navigate(id){
    if(unread > 0) setUnread(unread - 1);
    history.push(`/app/${role}/minhas-notificacoes/${id}`);
  }

  function shortText(text, size = 8){
    if(!text) return "";
    // text = text.replace(/(\r\n|\n|\r)/gm, "");
    const arr = text.split(" ");
    return arr.splice(0, size).join(" ")+"...";
  }

  const List = () => (

    <div className='notifications'>
      <h4>Notificações</h4>
      <ul>
        {
          notifications.map(value => (
            <li key={value.id} onClick={() => navigate(value.id)}>
                <h5>{shortText(value.titulo)}</h5>
                <Grid container justify="space-between">
                  <p>{shortText(value.descricao, 5)}</p>
                  <p>{dateParser(value.created_at)}</p>
                </Grid>
            </li>
          ))
        }
      </ul>
      <a className="view-more" href="/notificacoes">Ver Todos</a>
    </div>

  )

  return (
    <>
      <DropDownMenu
        action={
          <Badge badgeContent={unread} color="error">
            <FiBell color={"#D8DFE5"} size={20} />
          </Badge> 
        } 
        component={<List />} 
      />
    </>
  );
}