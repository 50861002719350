import io from "socket.io-client";
import axios from "axios";

const SOCKET_URL = process.env.REACT_APP_API_URL;

async function refresh_token(oldToken){

    try {

        const request = await axios.get(`/public/auth/refresh/${oldToken}`);
        const response = request.data;
        return response.token;

    } catch (error) {
        console.log(error);
    }

}

export const connect = (token) => {
    
    const socket = io.connect(SOCKET_URL, {
        query: `token=${token}`,
        // extraHeaders: { Authorization: `Bearer ${token}` }
    });

    socket.on("connect", () => {
        console.log("connectado")
    });

    socket.on("message", (msg) => {
        console.log(msg)
    });

    socket.on("unauthorized", () => {
        console.log("msg");
    });

    return socket;
    
}