import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Fab, Paper, Switch, Select, MenuItem } from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import masks from "others/mask";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import "./index.scss";
import { FiArrowLeft, FiCamera } from "react-icons/fi";
import { changedValues, prismaNewValues } from "others/services";

function VendedorEditar(){

    const [original, setOriginal] = useState({});
    const [vendedor, setVendedor] = useState({
        credencial: {
            ativo: true,
        },
        endereco: {
            estado: ''
        },
        imagem_url: "https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png",
        genero: { id: '' }
    });

    const [{socket}, dispatch] = useStateValue();
    const uploadInput = useRef(null);

    const history = useHistory();
    const params = useParams();

    useEffect(() => {

        if(socket){
            const { id } = params;
            const parserID = Number(id);
            if(parserID) loadVendedor(id);
        }
      
    }, [socket]);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadVendedor(id){

        socket.emit("vendedores_find", { vendedor_id: id });

        socket.on("vendedores_find", ({payload}) => {
            setVendedor(payload);
            setOriginal(payload);
            document.title = 'BSI - '+ payload.nome;
        });

    }

    async function save(){

        if(vendedor.telefone) vendedor.telefone = vendedor.telefone.replace(/\D/g, '');
        if(vendedor.celular) vendedor.celular = vendedor.celular.replace(/\D/g, '');
        
        const newVendedor = changedValues(original, vendedor);
        const credencial = prismaNewValues(original.credencial, vendedor.credencial);
        const genero = changedValues(original.genero, vendedor.genero);

        let payload = {...newVendedor, id: vendedor.id};
        if(credencial) payload.credencial = credencial;
        
        if(genero && genero.id){
            payload.genero = {
                connect : { id: genero.id }
            }
        }

        try {
            const { id } = vendedor;

            if(id) socket.emit("vendedores_update", payload);
            if(!id) socket.emit("vendedores_create", payload);

            socket.on("vendedores_create", (request) => {
                if(!request.error) success(request);
                if(request.error) error(request);
            });

            socket.on("vendedores_update", (request) => {
                if(!request.error) success(request);
                if(request.error) error(request);
            });
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    function success(response){

        showFlash({type: "success", title: "Sucesso", subTitle: response.msg});

        setTimeout(() => {
            history.push("/app/empresa/vendedores");
        }, 1000);

    }

    function error(response){

        showFlash({type: "error", title: "Error", subTitle: response.msg});

        setTimeout(() => {
            history.push("/app/empresa/vendedores");
        }, 1000);

    }


    return ( 
        <>
            <Grid className="vendedores editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../vendedores">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Informações legais
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                                <Grid container>
                                    {/* <label htmlFor="input-2">Razão Social</label> */}
                                    <input autoFocus={true} placeholder="Insira o nome" className="big-input" defaultValue={vendedor.nome} onChange={(evt) => setVendedor({...vendedor, nome: evt.target.value})} />
                                </Grid>
                                    
                                <Grid container spacing={2}>
                                    
                                    <Grid item md={3} xs={6}>
                                        <div className="avatar-wrapper">
                                            <img src={vendedor.imagem_url || 'https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png'} alt=""/>
                                            <input ref={uploadInput} accept="image/*" type="file" />
                                            <Fab onClick={() => uploadInput.current.click() } size="medium" className="primary-gradient"> <FiCamera size={20} /></Fab>
                                        </div>
                                    </Grid>

                                    <Grid item md>

                                        <Grid container spacing={2}>

                                            <Grid item md={3} xs={6}>
                                                <div className="select-wrapper">
                                                    <label htmlFor="input-14">Gênero</label>
                                                    <Select value={vendedor.genero.id} onChange={(evt) => setVendedor({...vendedor, genero: { id: evt.target.value } } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                        <MenuItem value={1}><span>Masculino</span></MenuItem>
                                                        <MenuItem value={2}><span>Feminino</span></MenuItem>
                                                        <MenuItem value={3}><span>Não Informado</span></MenuItem>
                                                    </Select> 
                                                </div>
                                            </Grid>

                                            <Grid item md={1} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-4">Habilitado</label>
                                                    <Switch
                                                        checked={vendedor.credencial.ativo}
                                                        color="primary"
                                                        onClick={() => setVendedor({...vendedor, credencial: {...vendedor.credencial, ativo: !vendedor.credencial.ativo } })}
                                                    />
                                                </div>
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={2}>

                                            <Grid item md={4} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-5">Email</label>
                                                    <input defaultValue={vendedor.credencial.email} onChange={(evt) => setVendedor({...vendedor, credencial: {...vendedor.credencial, email: evt.target.value }})} id="input-5" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-6">Telefone</label>
                                                    <MaskedInput guide={null} mask={masks.phone} defaultValue={vendedor.telefone} onChange={(evt) => setVendedor({...vendedor, telefone: evt.target.value})} id="input-6" />
                                                </div>
                                            </Grid>
                                            
                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-7">Celular</label>
                                                    <MaskedInput guide={null} mask={masks.phone} defaultValue={vendedor.celular} onChange={(evt) => setVendedor({...vendedor, celular: evt.target.value})} id="input-7" />
                                                </div>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>                               
                            </form>
                            <br/>
                        </Paper>
                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../vendedores" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default VendedorEditar;