import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDlwZNIWwmMF_sBXHHZ2YVLNIiZcUcX7mE",
    authDomain: "bsi-f8d77.firebaseapp.com",
    databaseURL: "https://bsi-f8d77.firebaseio.com",
    projectId: "bsi-f8d77",
    storageBucket: "bsi-f8d77.appspot.com",
    messagingSenderId: "604347305780",
    appId: "1:604347305780:web:0d0c27a28aeaad0037df61",
    measurementId: "G-61EFYXWM5Y"
};

firebase.initializeApp(firebaseConfig);