import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Fab, Paper, Switch, Select, MenuItem } from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import masks from "others/mask";
import Estados from "others/estados.json";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft, FiCamera } from "react-icons/fi";
import { changedValues, prismaNewValues } from "others/services";

function EntidadeEditar(){

    const [original, setOriginal] = useState({});

    const [entidade, setEntidade] = useState({
        usuario: {
            ativo: true,
        },
        endereco: {
            estado: ''
        },
        imagem_url: "https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png",
    });

    const [{}, dispatch] = useStateValue();
    const uploadInput = useRef(null);

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        const { id } = params;
        const parserID = Number(id);
        if(parserID) loadEntidade(id);
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadEntidade(id){
        const request = await axios.get(`entidades/${id}`);
        const response = request.data.payload;
        setOriginal(response);
        setEntidade(response);
        document.title = 'BSI - '+ response.razao_social;
    }

    async function save(){
        
        if(entidade.telefone) entidade.telefone = entidade.telefone.replace(/\D/g, '');
        if(entidade.cnpj) entidade.cnpj = entidade.cnpj.replace(/\D/g, '');
        if(entidade.endereco.cep) entidade.endereco.cep = Number(entidade.endereco.cep.toString().replace(/\D/g, ''));
        if(entidade.endereco.numero) entidade.endereco.numero = Number(entidade.endereco.numero);

        let newEntidade = changedValues(original, entidade);
        let endereco = prismaNewValues(original.endereco, entidade.endereco);
        let usuario = prismaNewValues(original.usuario, entidade.usuario);

        let payload = {...newEntidade};
        if(endereco) payload.endereco = endereco;
        if(usuario) payload.usuario = usuario;

        try {
            const { id } = entidade;
            const request = (id) ? await axios.put(`entidades/${id}`, payload) : await axios.post(`entidades`, payload);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push("/app/administrador/entidades");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    return ( 
        <>
            <Grid className="entidades editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../entidades">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Informações legais
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                                <Grid container>
                                    {/* <label htmlFor="input-2">Razão Social</label> */}
                                    <input placeholder="Razão Social / Nome" className="big-input" defaultValue={entidade.razao_social} onChange={(evt) => setEntidade({...entidade, razao_social: evt.target.value})} />
                                </Grid>
                                    
                                <Grid container spacing={2}>
                                    
                                    <Grid item md={3} xs={6}>
                                        <div className="avatar-wrapper">
                                            <img src={entidade.imagem_url} alt=""/>
                                            <input ref={uploadInput} accept="image/*" type="file" />
                                            <Fab onClick={() => uploadInput.current.click() } size="medium" className="primary-gradient"> <FiCamera size={20} /></Fab>
                                        </div>
                                    </Grid>

                                    <Grid item md>

                                        <Grid container spacing={2}>

                                            <Grid item md={4} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-1">Responsável Geral</label>
                                                    <input defaultValue={entidade.responsavel} onChange={(evt) => setEntidade({...entidade, responsavel: evt.target.value})} id="input-1" />
                                                </div>
                                            </Grid>

                                            <Grid item md={4} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-2">Responsável Financeiro</label>
                                                    <input disabled defaultValue={entidade.responsavel} onChange={(evt) => setEntidade({...entidade, responsavel: evt.target.value})} id="input-2" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-3">CNPJ</label>
                                                    <MaskedInput guide={false} mask={masks.cnpj} defaultValue={entidade.cnpj} onChange={(evt) => setEntidade({...entidade, cnpj: evt.target.value })} id="input-3" />
                                                </div>
                                            </Grid>

                                            <Grid item md={1} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-4">Habilitado</label>
                                                    <Switch
                                                        checked={entidade.usuario.ativo}
                                                        color="primary"
                                                        onClick={() => setEntidade({...entidade, usuario: {...entidade.usuario, ativo: !entidade.usuario.ativo } })}
                                                    />
                                                </div>
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={2}>

                                            <Grid item md={4} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-5">Email</label>
                                                    <input defaultValue={entidade.usuario.email} onChange={(evt) => setEntidade({...entidade, usuario: {...entidade.usuario, email: evt.target.value }})} id="input-5" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-6">Telefone</label>
                                                    <MaskedInput guide={null} mask={masks.phone} defaultValue={entidade.telefone} onChange={(evt) => setEntidade({...entidade, telefone: evt.target.value})} id="input-6" />
                                                </div>
                                            </Grid>
                                            
                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-7">Celular</label>
                                                    <MaskedInput disabled guide={null} mask={masks.phone} defaultValue={entidade.celular} onChange={(evt) => setEntidade({...entidade, celular: evt.target.value})} id="input-7" />
                                                </div>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>                               
                            </form>
                            <br/>
                        </Paper>
                    </div>
                    <div className="page-box">
                        <div className="title">
                            Endereço
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item md={2} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-11">CEP</label>
                                            <MaskedInput guide={false}mask={masks.cep} defaultValue={entidade.endereco.cep} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, cep: evt.target.value } } )} id="input-11" />
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-12">Endereço</label>
                                            <input defaultValue={entidade.endereco.logradouro} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, logradouro: evt.target.value } } )} id="input-12" />
                                        </div>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-13">Número</label>
                                            <input defaultValue={entidade.endereco.numero} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, numero: evt.target.value } } )} id="input-13" />
                                        </div>
                                    </Grid>
                                </Grid>
                                
                                <Grid container spacing={2}>
                                    <Grid item md xs={6}>
                                        <div className="select-wrapper">
                                            <label htmlFor="input-14">Estado</label>
                                            <Select value={entidade.endereco.estado || ''} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, estado: evt.target.value } } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                { Estados.map((value, i) => <MenuItem value={value.sigla} key={i}><span>{value.desc}</span></MenuItem>) }
                                            </Select> 
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-15">Cidade</label>
                                            <input defaultValue={entidade.endereco.cidade} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, cidade: evt.target.value } } )} id="input-15" />
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-16">Bairro</label>
                                            <input defaultValue={entidade.endereco.bairro} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, bairro: evt.target.value } } )} id="input-16" />
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-17">Complemento</label>
                                            <input defaultValue={entidade.endereco.complemento} onChange={(evt) => setEntidade({...entidade, endereco: {...entidade.endereco, complemento: evt.target.value } } )} id="input-14" />
                                        </div>
                                    </Grid>
                                </Grid>

                            </form>
                        </Paper>
                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../entidades" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default EntidadeEditar;