import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import "./index.scss";
import axios from "axios";
import { dateParser } from "others/services";
import { useStateValue } from 'contexts';

function Exames(){

    document.title = 'BSI - Lista de Clientes';

    const [clientes, setClientes] = useState([]);
    const [{socket}, dispatch] = useStateValue();

    useEffect(() => {
        if(socket) loadClientes();
    }, [socket]);

    async function loadClientes(){
        
        socket.emit("clientes_list");

        socket.on("clientes_list", ({payload}) => payload && setClientes(payload) );

    }

    async function removeExame(id){
        alert("ainda não pode remover um beneficiario");
    }

    return ( 
        <>
            <Grid className="clientes listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Clientes
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th> 
                                <th>Email</th> 
                                <th>Data</th> 
                                <th>Telefone</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                clientes.map(value => (
                                    <tr key={value.id}>
                                        <td>#{value.id}</td>
                                        <td>{value.nome}</td>
                                        <td>{value.email}</td>
                                        <td>{dateParser(value.created_at)}</td>
                                        <td>{value.telefone}</td>
                                        <td>
                                            <a className="btn blue-bg small" href={`${value.id}`}>Editar</a>
                                            <a className="btn gray-bg small" onClick={() => removeExame(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Exames;