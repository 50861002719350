import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import Header from "components/header";
import { useStateValue } from 'contexts';

import Administrador from "./administrador";
import Empresa from "./empresa";
import { connect } from "../../socket";

require("../../firebase.js");

function Private({permission, Component, header}){
    let history = useHistory();

    const localData = JSON.parse(localStorage.getItem("@bsi"));
    const { auth: { token, role } } = localData;

    if(!token || role !== permission) return history.push("/login");

    return (
        <>
            <Header />
            <div className="app wrapper">
                <Component />
            </div>
        </>
    )

}

function createSocketConnection(dispatch, token){

    const socket = connect(token);

    dispatch({
        type: 'setSocket',
        payload: socket
    });


  }

function loadDefault(dispatch){

    function loadUser(){
        const localData = JSON.parse(localStorage.getItem("@bsi"));
        const { auth: { token } } = localData;

        if(!localData) return;

        createSocketConnection(dispatch, token);

        dispatch({
            type: 'setUser',
            payload: localData.user
        });

    }

    loadUser();
}

function App(){
    
    let { path, isExact } = useRouteMatch();
    let history = useHistory();

    const [{}, dispatch] = useStateValue();

    useEffect(() => loadDefault(dispatch), []);
    
    useEffect(() => {

        if(path === '/app' && isExact) {
            const localData = JSON.parse(localStorage.getItem("@bsi"));
            const { auth: { role }} = localData;
            console.log(role);

            if(role === 'admin') return history.push("/app/administrador");
            if(role === 'empresa') return history.push("/app/empresa");
        }

    },[path, isExact]);

    return ( 
        <Switch>
            <Route path={path+'/administrador'}>
                <Private permission={"admin"} Component={Administrador} />
            </Route>
            <Route path={path+'/empresa'}>
                <Private permission={"empresa"} Component={Empresa} />
            </Route>
        </Switch>
    )
    
}

export default App;