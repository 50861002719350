import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import "./index.scss";
import { GoPencil } from "react-icons/go";
import { useStateValue } from 'contexts';

function Vendedores(){

    document.title = 'BSI - Lista de Vendedores';

    const [vendedores, setVendedores] = useState([]);
    const [{socket}, dispatch] = useStateValue();

    useEffect(() => {
        if(socket) loadvendedores();
    }, [socket]);

    async function loadvendedores(){
        
        socket.emit("vendedores_list");
        socket.on("vendedores_list", ({payload}) => payload && setVendedores(payload));

    }

    async function removePrestador(id){
        alert("Ainda não foi implementado a regra pra exclusão de entidade!");
    }

    return ( 
        <>
            <Grid className="vendedores listar" container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <div className="float-left">
                            <h3>Lista de vendedores</h3>
                            <small>({vendedores.length})</small>
                        </div>
                        <Button className="float-right" color="primary" variant="contained" href="vendedores/novo">Novo Vendedor</Button>
                    </div>
                </Grid>
                <div className="page-box">
                    <Grid container spacing={3}>
                        {
                            vendedores.map(value => (
                                <Grid item md={3} key={value.id}>

                                    <Paper elevation={3}>
                                            <Grid container className="card">
                                                <Grid item xs={12}>
                                                    <Grid container justify="space-between" className="status">
                                                        {
                                                            value.atividades.length ? (
                                                                <small><div className={"circle " + value.atividades[0].slug}></div> {value.atividades[0].titulo}</small>
                                                        ): (
                                                            <small><div className="circle"></div>Indisponivel</small>
                                                            )
                                                        }
                                                        <a href={`vendedores/${value.id}`}><GoPencil size={18} className="edit-icon" /></a> 
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="avatar-wrapper">
                                                        <div className="avatar">
                                                            <img src={value.imagem_url || 'https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png'} alt=""/>
                                                        </div>
                                                        <h4 className="name text-center">{ value.nome }</h4>
                                                        <h5 className="text-center">{ value.email }</h5>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="statistics">
                                                    <Grid container justify="space-between">
                                                        <Grid container justify="space-between">
                                                            <div className="stats">
                                                                {/* <FiUsers size={18} /> */}
                                                                <small>Atendimentos</small>
                                                                <h2>{value.atendimentos.length}</h2>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Paper>

                                </Grid>
                            ))
                        }
                    </Grid>
                </div>

            </Grid>

        </>
    )
    
}

export default Vendedores;