import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Fab, Paper, Switch, Select, MenuItem } from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import masks from "others/mask";
import Estados from "others/estados.json";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft } from "react-icons/fi";
import { changedValues, prismaNewValues } from "others/services";

function PacienteEditar(){

    const [original, setOriginal] = useState({});
    const [beneficiario, setBeneficiario] = useState({
        endereco: {
            estado: ''
        },
        genero: { id: '' }
    });

    const [{}, dispatch] = useStateValue();

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        const { id } = params;
        const parserID = Number(id);
        if(parserID) loadBeneficiario(id);
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadBeneficiario(id){
        
        const request = await axios.get(`beneficiarios/${id}`);
        const response = request.data.payload;

        if(!response) return null;

        if(!response.endereco) response.endereco = { estado: "" };
        if(!response.genero) response.genero = { id: "" };

        setBeneficiario(response);
        setOriginal(response);
        
        document.title = 'BSI - '+ response.nome;
    }

    async function save(){

        if(beneficiario.telefone) beneficiario.telefone = beneficiario.telefone.replace(/\D/g, '');
        if(beneficiario.celular) beneficiario.celular = beneficiario.celular.replace(/\D/g, '');
        if(beneficiario.cpf) beneficiario.cpf = beneficiario.cpf.replace(/\D/g, '');
        
        if(beneficiario.endereco.cep) beneficiario.endereco.cep = Number(beneficiario.endereco.cep.toString().replace(/\D/g, ''));
        if(beneficiario.endereco.numero) beneficiario.endereco.numero = Number(beneficiario.endereco.numero);

        const newbeneficiario = changedValues(original, beneficiario);
        const endereco = prismaNewValues(original.endereco, beneficiario.endereco);
        const genero = changedValues(original.genero, beneficiario.genero);

        let payload = {...newbeneficiario};
        if(endereco) payload.endereco = endereco;
        
        if(genero && genero.id){
            payload.genero = {
                connect : { id: genero.id }
            }
        }

        try {
            const { id } = beneficiario;
            const request = (id) ? await axios.put(`beneficiarios/${id}`, payload) : await axios.post(`beneficiarios`, payload);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push("/app/prestador/pacientes/lista");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    return ( 
        <>
            <Grid className="beneficiarios editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../beneficiarios">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Informações legais
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                                <Grid container>
                                    {/* <label htmlFor="input-2">Razão Social</label> */}
                                    <input autoFocus={true} placeholder="Nome do Paciente" className="big-input" defaultValue={beneficiario.nome} onChange={(evt) => setBeneficiario({...beneficiario, nome: evt.target.value})} />
                                </Grid>
                                    
                                <Grid container spacing={2}>

                                    <Grid container spacing={2}>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-3">CPF</label>
                                                <MaskedInput guide={false} mask={masks.cpf} defaultValue={beneficiario.cpf} onChange={(evt) => setBeneficiario({...beneficiario, cpf: evt.target.value})} id="input-3" />
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="select-wrapper">
                                                <label htmlFor="input-14">Gênero</label>
                                                <Select value={beneficiario.genero.id} onChange={(evt) => setBeneficiario({...beneficiario, genero: { id: evt.target.value } } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                    <MenuItem value={1}><span>Masculino</span></MenuItem>
                                                    <MenuItem value={2}><span>Feminino</span></MenuItem>
                                                    <MenuItem value={3}><span>Não Informado</span></MenuItem>
                                                </Select> 
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2">Data de Nascimento</label>
                                                <MaskedInput guide={false} mask={masks.date} defaultValue={beneficiario.data_nascimento} onChange={(evt) => setBeneficiario({...beneficiario, data_nascimento: evt.target.value})} id="input-3" />
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2">Idade</label>
                                                <input type="number" max={99} defaultValue={beneficiario.idade} onChange={(evt) => setBeneficiario({...beneficiario, idade: evt.target.value } )} id="input-12" />
                                            </div>
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2}>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2">Email</label>
                                                <input type="email" defaultValue={beneficiario.email} onChange={(evt) => setBeneficiario({...beneficiario, email: evt.target.value } )} id="input-12" />
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-6">Telefone</label>
                                                <MaskedInput guide={null} mask={masks.phone} defaultValue={beneficiario.telefone} onChange={(evt) => setBeneficiario({...beneficiario, telefone: evt.target.value})} id="input-6" />
                                            </div>
                                        </Grid>
                                        
                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-7">Celular</label>
                                                <MaskedInput guide={null} mask={masks.phone} defaultValue={beneficiario.celular} onChange={(evt) => setBeneficiario({...beneficiario, celular: evt.target.value})} id="input-7" />
                                            </div>
                                        </Grid>

                                    </Grid>

                                </Grid>                               
                            </form>
                            <br/>
                        </Paper>
                    </div>
                    <div className="page-box">
                        <div className="title">
                            Endereço
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item md={2} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-11">CEP</label>
                                            <MaskedInput guide={false} mask={masks.cep} defaultValue={beneficiario.endereco.cep} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, cep: evt.target.value } } )} id="input-11" />
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-12">Endereço</label>
                                            <input defaultValue={beneficiario.endereco.logradouro} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, logradouro: evt.target.value } } )} id="input-12" />
                                        </div>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-13">Número</label>
                                            <input defaultValue={beneficiario.endereco.numero} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, numero: evt.target.value } } )} id="input-13" />
                                        </div>
                                    </Grid>
                                </Grid>
                                
                                <Grid container spacing={2}>
                                    <Grid item md xs={6}>
                                        <div className="select-wrapper">
                                            <label htmlFor="input-14">Estado</label>
                                            <Select value={beneficiario.endereco.estado || ''} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, estado: evt.target.value } } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                { Estados.map((value, i) => <MenuItem value={value.sigla} key={i}><span>{value.desc}</span></MenuItem>) }
                                            </Select> 
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-15">Cidade</label>
                                            <input defaultValue={beneficiario.endereco.cidade} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, cidade: evt.target.value } } )} id="input-15" />
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-16">Bairro</label>
                                            <input defaultValue={beneficiario.endereco.bairro} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, bairro: evt.target.value } } )} id="input-16" />
                                        </div>
                                    </Grid>
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-17">Complemento</label>
                                            <input defaultValue={beneficiario.endereco.complemento} onChange={(evt) => setBeneficiario({...beneficiario, endereco: {...beneficiario.endereco, complemento: evt.target.value } } )} id="input-17" />
                                        </div>
                                    </Grid>
                                </Grid>

                            </form>
                        </Paper>
                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../beneficiarios" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default PacienteEditar;