const navigation = {

    admin: {
        toolbar:[
            { title: "", path: "" },
        ],
        shortcuts:[
            { title: "Inicio", path: "/app/administrador/dashboard", icon: "FiHome" },
            { title: "Entidades", path: "/app/administrador/entidades", icon: "FiServer" },
            { title: "Notificacoes", path: "/app/administrador/notificacoes", icon: "FiBell" },
            { title: "Configurações", path: "/app/administrador/configuracoes", icon: "FiSettings" },
        ]
    },
    empresa: {
        toolbar:[
        ],
        shortcuts:[
            { title: "Inicio", path: "/app/empresa/dashboard", icon: "FiHome" },
            { title: "Vendedores", path: "/app/empresa/vendedores", icon: "FiBriefcase" },
            { title: "Clientes", path: "/app/empresa/clientes", icon: "FiUsers" },
            { title: "Atendimentos", path: "/app/empresa/atendimentos", icon: "FiBook" },
            { title: "Notificacoes", path: "/app/empresa/notificacoes", icon: "FiBell" },
            { title: "Relatórios", path: "/app/empresa/relatorios", icon: "FiFileText" },
            { title: "Customização", path: "/app/empresa/customizacao", icon: "FiDroplet" },
            { title: "Horário", path: "/app/empresa/horario", icon: "FiClock" },
        ]
    }
    
}

export default navigation;