import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1`;

function getToken(){

    try {
        const localData = JSON.parse(localStorage.getItem("@bsi"));
        const { auth: { token }} = localData;
        return token;
        
    } catch (error) {
        return null;
    }
}


axios.interceptors.request.use(
    (request) => {
        const jToken = getToken();

        if(!jToken) return request;

        request.headers.Authorization = jToken;
        
        return request;
    },
    error => Promise.reject(error),
);