import React, { useEffect, useState } from 'react';

import { Grid, Paper, LinearProgress } from '@material-ui/core';
import './index.scss';
import { useStateValue } from 'contexts';
import axios from "axios";

function Statistics(){

    const [ stats, setStats ] = useState({
        atendimentos: {
            minutos: {}
        },
        solicitacoes: {},
        diagnosticos: {}
    });
    const [{ user : { tipo } }] = useStateValue();

    useEffect(() => {
        loadStats();
    },[]);

    async function loadStats(){
        const request = await axios.get('/estatisticas/resumo');
        const response = request.data.payload;
        setStats(response);
    }

    function barSize(init, total){
        return Number(init) / Number(total) * 100; 
    }

    return (
        <Paper elevation={3} className="total-progress">
            <Grid container className="infos">
                <h4>Resumo de Atividades</h4>
                <Grid container className="info" direction="column">
                    <Grid container justify="space-between">
                        <h5>Clientes</h5>
                        <h5><b>12</b></h5>
                    </Grid>
                    <LinearProgress className="progress-blue" variant="determinate" value={barSize(stats.atendimentos.prestador, stats.atendimentos.total)} />
                </Grid>
                <Grid container className="info" direction="column">
                    <Grid container justify="space-between">
                        <h5>Atendimentos</h5>
                        <h5><b>1</b></h5>
                    </Grid>
                    <LinearProgress className="progress-green" variant="determinate" value={barSize(stats.diagnosticos.prestador, stats.diagnosticos.total)} />
                </Grid>
                <Grid container className="info" direction="column">
                    <Grid container justify="space-between">
                        <h5>Mensagens</h5>
                        <h5><b>4</b></h5>
                    </Grid>
                    <LinearProgress className="progress-yellow" variant="determinate" value={barSize(stats.solicitacoes.prestador, stats.solicitacoes.total)} />
                </Grid>
                <Grid container className="info" direction="column">
                    <Grid container justify="space-between">
                        <h5>Tempo em atendimento</h5>
                        <h5><b>60 min</b></h5>
                    </Grid>
                    <LinearProgress className="progress-blue" variant="determinate" value={barSize(stats.atendimentos.minutos.atendendido, stats.atendimentos.minutos.total)} />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Statistics;