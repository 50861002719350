import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";

import Vendedores from "./vendedores";
import VendedoresEditar from "./vendedores/editar";

import Clientes from "./clientes";
import ClientesEditar from "./clientes/editar";

import Atendimentos from "./atendimentos";

import Notificacoes from "pages/notificacoes";
import NotificacoesEditar from "pages/notificacoes/editar";
import MinhasNotificacoes from "pages/notificacoes/single";

import Estatisticas from "./estatisticas";

import Horario from "./horario";
import Customizacao from "./customizacao";

const Auth = () => {
    let { path } = useRouteMatch();

    return ( 
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/dashboard`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/vendedores`}>
                <Vendedores />
            </Route>
            <Route exact path={`${path}/vendedores/:id`}>
                <VendedoresEditar />
            </Route>
            <Route exact path={`${path}/clientes`}>
                <Clientes />
            </Route>
            <Route exact path={`${path}/clientes/:id`}>
                <ClientesEditar />
            </Route>
            <Route exact path={`${path}/atendimentos`}>
                <Atendimentos />
            </Route>
            <Route exact path={`${path}/notificacoes`}>
                <Notificacoes />
            </Route>
            <Route exact path={`${path}/notificacoes/:id`}>
                <NotificacoesEditar />
            </Route>
            <Route exact path={`${path}/minhas-notificacoes/:id`}>
                <MinhasNotificacoes />
            </Route>
            <Route exact path={`${path}/estatisticas`}>
                <Estatisticas />
            </Route>
            <Route exact path={`${path}/customizacao`}>
                <Customizacao />
            </Route>
            <Route exact path={`${path}/horario`}>
                <Horario />
            </Route>
        </Switch>
    )
    
}

export default Auth;