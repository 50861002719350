const users = [ 
    { 
        id: 12112321, 
        avatar: "http://admincast.com/adminca/preview/admin_1/html/assets/img/users/u3.jpg",
        nome: "Ernesto Coutinho", 
        login: "administrador", 
        senha: "admin123", 
        tipo: "admin",
        email: "administrador@bsi.com.br",
        idade: 30,
        genero: "Masculino",
        telefone: "(27) 99641-5494"
    },
    { id: 12212321,
        avatar: "http://admincast.com/adminca/preview/admin_1/html/assets/img/users/u7.jpg",
        nome: "Vanessa Carvalhais",
        login: "beneficiario",
        senha: "admin123",
        tipo: "beneficiario", 
        email: "beneficiario@bsi.com.br",
        idade: 26,
        genero: "Feminino",
        telefone: "(27) 2241-4645"
    },
    { id: 12312321,
        avatar: "http://admincast.com/adminca/preview/admin_1/html/assets/img/users/u6.jpg",
        nome: "Renato Ferraço",
        login: "prestador",
        senha: "admin123",
        tipo: "prestador",
        email: "prestador@bsi.com.br",
        idade: 40,
        genero: "Masculino",
        telefone: "(27) 3250-9870",
        conselho: "CRM",
        uf: "ES",
        numero: Math.floor(100000 + Math.random() * 900000),
        cbo: 225125
    },
    { id: 12712321,
        avatar: "http://admincast.com/adminca/preview/admin_1/html/assets/img/users/u11.jpg",
        nome: "Samuel Loureiro",
        login: "auditor",
        senha: "admin123",
        tipo: "auditor",
        email: "auditoria@bsi.com.br",
        idade: 32,
        genero: "Masculino",
        telefone: "(27) 99641-5494"
    },
];

export default users;