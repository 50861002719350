import React, { useState, useEffect } from 'react';
import { Grid, Paper, LinearProgress } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { FiUser, FiBook, FiClipboard } from "react-icons/fi";
import ReactMapGL from 'react-map-gl';

function Map() {

    const [viewport, setViewport] = useState({
      width: "100%",
      height: 360,
      latitude: -20.1967316,
      longitude: -40.266438,
      zoom: 13
    });
  
    return (
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={"pk.eyJ1IjoiZGV2Y29zdGEiLCJhIjoiY2tjemt6eWZqMGt0MjJ5cGpiN3R6OWJjbSJ9.cVpjDDts5jhmWgCzTwBmqg"}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      />
    );
}

function Prestadores(){

    document.title = 'BSI - Estatisticas';

    const [prestadores, setPrestadores] = useState([]);

    useEffect(() => {
        loadPrestadores();
    }, []);

    async function loadPrestadores(){
        const request = await axios.get("/prestadores");
        const response = request.data.payload;
        setPrestadores(response);
    }

    async function removePrestador(id){
        alert("Ainda não foi implementado a regra pra exclusão de entidade!");
    }

    return ( 
        <>
            <div className="estatisticas">
                <Grid container className="main-cards" spacing={2}>
                    <Grid item md={4}>
                        <Paper className="card-mod-1" elevation={3}>
                            <h4 className="card-title">Beneficiários</h4>
                            <Grid container alignItems="center">
                                <div className="card-icon purple-bg"><FiUser /></div>
                                <div className="card-icon-text purple-text">96</div>
                                <p><a href="#">Clique aqui</a> parar listar todos os beneficiarios cadastrados no periodo</p>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper className="card-mod-1" elevation={3}>
                            <h4 className="card-title">Solicitações</h4>
                            <Grid container alignItems="center">
                                <div className="card-icon green-bg"><FiClipboard /></div>
                                <div className="card-icon-text green-text">139</div>
                                <p><a href="#">Clique aqui</a> parar listar todas solicitações realizados no periodo</p>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper className="card-mod-1" elevation={3}>
                            <h4 className="card-title">Diagnosticos</h4>
                            <Grid container alignItems="center">
                                <div className="card-icon blue-bg"><FiBook /></div>
                                <div className="card-icon-text blue-text">216</div>
                                <p><a href="#">Clique aqui</a> parar listar todos Diagnosticos realizados no periodo</p>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: 10}}>
                    <Grid item md={4}>
                        <Paper className="card total-progress" elevation={3}>
                            <h4 className="card-title">Sintomas mais comuns</h4>
                            <Grid container className="infos">
                                <Grid container className="info" direction="column">
                                    <Grid container justify="space-between">
                                        <h5>Crack</h5>
                                        <h5><b>123</b></h5>
                                    </Grid>
                                    <LinearProgress className="progress-blue" variant="determinate" value={55} />
                                </Grid>
                                <Grid container className="info" direction="column">
                                    <Grid container justify="space-between">
                                        <h5>Crack</h5>
                                        <h5><b>123</b></h5>
                                    </Grid>
                                    <LinearProgress className="progress-blue" variant="determinate" value={55} />
                                </Grid>
                                <Grid container className="info" direction="column">
                                    <Grid container justify="space-between">
                                        <h5>Crack</h5>
                                        <h5><b>123</b></h5>
                                    </Grid>
                                    <LinearProgress className="progress-blue" variant="determinate" value={55} />
                                </Grid>
                                <Grid container className="info" direction="column">
                                    <Grid container justify="space-between">
                                        <h5>Crack</h5>
                                        <h5><b>123</b></h5>
                                    </Grid>
                                    <LinearProgress className="progress-blue" variant="determinate" value={55} />
                                </Grid>
                                <Grid container className="info" direction="column">
                                    <Grid container justify="space-between">
                                        <h5>Crack</h5>
                                        <h5><b>123</b></h5>
                                    </Grid>
                                    <LinearProgress className="progress-blue" variant="determinate" value={55} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={8}>
                        <Paper elevation={3} className="map">
                            <Map />
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        </>
    )
    
}

export default Prestadores;