import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import "./index.scss";
import { useStateValue } from 'contexts';
import { FiPlus, FiCheck, FiTrash, FiX } from "react-icons/fi";
import Slider from "react-slick";

const diasDisponiveis = [
    { title: "Domingo", value: "sunday", times: []},
    { title: "Segunda-feira", value: "monday", times: []},
    { title: "Terça-feira", value: "tuesday", times: []},
    { title: "Quarta-feira", value: "wednesday", times: []},
    { title: "Quinta-feira", value: "thursday", times: []},
    { title: "Sexta-feira", value: "friday", times: []},
    { title: "Sábado", value: "saturday", times: []},
];

const horariosDisponiveis = [
    "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", 
    "07:00", "08:00", "09:00", "10:00", "11:00", "12:00"
];

function Atendimentos(){

    document.title = 'BSI - Horário de Atendimento';

    const [horarios, setHorarios] = useState([]);
    const [current, setCurrent] = useState(0);
    const [createNew, setCreateNew] = useState(false);
    const [modifyTime, setModify] = useState(null);
    const [{socket}, dispatch] = useStateValue();

    useEffect(() => {
        if(socket) mounted();
        setHorarios(diasDisponiveis);
    }, [socket]);

    const slickConfig = {
		speed: 200,
		slidesToShow: 1,
		infinite: true,
		nextArrow: false,
		prevArrow: false
    };

    async function mounted(){
        socket.emit("configuracoes_find");
        
        socket.on("configuracoes_find", ({payload}) => {
            console.log(payload);
            if(payload) {
                if(payload.horarios) setHorarios(payload.horarios);
            }
        });

    }

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    function newTime(){

        const newDays = horarios.map((value, index) => {
            if(index === current){
                value.times.push({initial: { type: "am", time: "08:00" }, final: { type: "pm", time: "10:00" } });
            }
            return value;
        });

        setHorarios(newDays);
        setCreateNew(true);
    }

    function remove(index){

        const newDays = horarios.filter((value, single) => {
            if(single === current){
                value.times.splice(index, 1);
            }
            return value;
        });

        setHorarios(newDays);
        save();
    }

    function cancelTime(){

        const index = horarios[current].times.length - 1;

        remove(index);
        setCreateNew(false);

    }

    function updateTime(index, type, timeIndex){

        let newHorarios = [...horarios];

        const time = horariosDisponiveis[timeIndex];

        newHorarios[current].times[index][type].time = time;

        setHorarios(newHorarios);

        setModify(index);

    }

    function updateTimeType(index, type, value){

        value = value === 0 ? "am" : "pm";

        let newHorarios = [...horarios];

        newHorarios[current].times[index][type].type = value;

        setHorarios(newHorarios);

    }

    function save(){

        socket.emit("configuracoes_update", { horarios });
           
        socket.on('configuracoes_update', (response) => {
            setCreateNew(false);
            setModify(null);
            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
        });

    }

    function getTimeIndex(input){
        return horariosDisponiveis.findIndex(value => value === input.time);
    }

    function changeDay(index){
        setCurrent(null);
        setTimeout(() => { setCurrent(index); }, 200)
    }

    return ( 
        <>
            <Grid className="horario" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Configuração do Atendimento
                    </div>
                    <Paper elevation={3} className="content">
                        <Grid container>
                            <Grid item md={4}>
                                <div className="side">
                                    {/* <p className="inside-title">Selecione o dia para editar</p> */}
                                    { diasDisponiveis.map((value, index) => 
                                        <div onClick={() => changeDay(index)} key={index} className={`week-day ${current === index && "active"}`}>
                                            <h4>{value.title}</h4>
                                            <small>{value.time && value.time.length === 0 ? "Indisponivel" : "Disponivel"}</small>
                                        </div>
                                    ) }
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <p className="inside-title">Selecione o horario para editar</p>
                                
                                {
                                    horarios[current] && horarios[current].times.map((value, index) => {

                                        return(
                                            <Grid container className="times" key={index}>
                                                
                                                <div className="time active">
                                                    <h4>Inicio</h4>
                                                    <Grid item className="card">
                                                        <Slider {...slickConfig} initialSlide={ getTimeIndex(value.initial) } afterChange={timeIndex => updateTime(index, 'initial', timeIndex)}>
                                                            { horariosDisponiveis.map((value, index) => <h2 key={index}>{value}</h2>)}
                                                        </Slider>
                                                        <Slider {...slickConfig} initialSlide={ value.initial.type === 'am' ? 0 : 1 } afterChange={typeIndex => updateTimeType(index, 'initial', typeIndex)}>
                                                            <p>Manhã (AM)</p>
                                                            <p>Tarde (PM)</p>
                                                        </Slider>
                                                    </Grid>
                                                </div>

                                                <div className="time active">
                                                    <h4>Fim</h4>
                                                    <Grid item className="card">
                                                        <Slider {...slickConfig} initialSlide={ getTimeIndex(value.final) } afterChange={timeIndex => updateTime(index, 'final', timeIndex)}>
                                                            { horariosDisponiveis.map((value, index) => <h2 key={index}>{value}</h2>)}
                                                        </Slider>
                                                        <Slider {...slickConfig} initialSlide={ value.final.type === 'am' ? 0 : 1 } afterChange={typeIndex => updateTimeType(index, 'final', typeIndex)}>
                                                            <p>Manhã (AM)</p>
                                                            <p>Tarde (PM)</p>
                                                        </Slider>
                                                    </Grid>
                                                </div>

                                                {
                                                    <div className="option" onClick={() => remove(index)}>
                                                        <FiTrash/>
                                                    </div>
                                                }
                                                {
                                                    modifyTime === index && <div className="option" onClick={() => save()}>
                                                        <FiCheck/>
                                                    </div>
                                                }
                                            </Grid>
                                        )
                                    })
                                     
                                }

                                <div className="actions">

                                    <div className="new-action">
                                        <div className="add-btn" onClick={() => newTime()}>
                                            <FiPlus/>
                                        </div>
                                    </div>
                                   
                                </div>

                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Grid>
        </>
    )
    
}

export default Atendimentos;