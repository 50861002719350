import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch
} from "react-router-dom";

import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";

import Auth from "pages/auth";
import App from "pages/app";
import NoMatch from "pages/404";

import 'assets/scss/main.scss';
import 'typeface-roboto';

import { StateProvider } from 'contexts';

import reducers from 'contexts/reducer';
import initialState from 'contexts/initialState';

import Flash from "components/flash";

require('./axios');

const Main = () => {

  document.title = "BSI";

  const { path, isExact } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if(path === '/' && isExact) history.push("login");
  },[path, isExact]);

  return (
    <MuiThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducers}>
        <Flash />
        <Router>
            <Switch>
              <Route exact path="/">
                <Auth />
              </Route>
              <Route path="/login">
                <Auth />
              </Route>
              <Route path="/app">
                <App />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
        </Router>
      </StateProvider>
    </MuiThemeProvider>
  );
}

export default Main;